var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "white-sheet",
    { staticClass: "watch-only-address-table" },
    [
      _c(
        "div",
        { staticClass: "block-header d-flex align-center" },
        [
          _c("mew-blockie", {
            staticClass: "mr-4 blockie",
            attrs: {
              height: "35px",
              width: "35px",
              address: "0xDECAF9CD2367cdbb726E904cD6397eDFcAe6068D",
            },
          }),
          _c("div", { staticClass: "truncate" }, [
            _c("div", { staticClass: "font-weight-medium" }, [
              _vm._v("Elmo's wallet 04"),
            ]),
            _c(
              "div",
              { staticClass: "d-flex align-center" },
              [
                _c("mew-transform-hash", {
                  attrs: { hash: "0x98d9964e1764654dtrhtrggfrv3049t04i" },
                }),
                _c("mew-copy", {
                  attrs: {
                    "copy-value": "0x98d9964e1764654dtrhtrggfrv3049t04i",
                    "is-small": true,
                    tooltip: _vm.$t("common.copy"),
                  },
                }),
              ],
              1
            ),
          ]),
          _c(
            "div",
            { staticClass: "ml-auto" },
            [
              _c(
                "v-btn",
                { attrs: { icon: "", depressed: "", color: "white" } },
                [_c("v-icon", [_vm._v("mdi-dots-vertical")])],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "white d-block d-lg-none px-5" },
        [
          _c(
            "div",
            {
              staticClass:
                "d-flex align-center justify-space-between pt-6 pb-2",
            },
            [
              _c("h5", { staticClass: "font-weight-medium" }, [
                _vm._v("ETH Balance"),
              ]),
              _c("div", { staticClass: "text-right" }, [
                _c("h5", { staticClass: "font-weight-bold" }, [
                  _vm._v("$2,000.00"),
                ]),
                _c("div", { staticClass: "greenPrimary--text" }, [
                  _vm._v("8.00123 ETH"),
                ]),
              ]),
            ]
          ),
          _c("v-divider"),
          _c(
            "div",
            { staticClass: "d-flex align-center justify-space-between py-4" },
            [
              _c("h5", { staticClass: "font-weight-medium" }, [
                _vm._v("Value of Tokens"),
              ]),
              _c("div", { staticClass: "text-right" }, [
                _c("h5", { staticClass: "font-weight-bold" }, [
                  _vm._v("$200.00"),
                ]),
                _c("div", { staticClass: "greenPrimary--text" }, [
                  _vm._v("4 Tokens"),
                ]),
              ]),
            ]
          ),
          _c("v-divider"),
          _c(
            "div",
            { staticClass: "d-flex align-center justify-space-between py-4" },
            [
              _c("h5", { staticClass: "font-weight-medium" }, [
                _vm._v("Total Wallet Value"),
              ]),
              _c("div", { staticClass: "text-right" }, [
                _c("h5", { staticClass: "font-weight-bold" }, [
                  _vm._v("$2,000.22"),
                ]),
                _c("div", { staticClass: "greenPrimary--text" }, [
                  _vm._v("18.00123 ETH"),
                ]),
              ]),
            ]
          ),
          _c("v-divider"),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass:
            "pa-7 d-none d-lg-flex justify-space-between align-center",
        },
        [
          _c("div", { staticClass: "d-flex align-start" }, [
            _c("img", {
              staticClass: "mr-4 mt-1",
              attrs: {
                src: require("@/assets/images/currencies/icon-eth-blue.svg"),
                height: "35",
              },
            }),
            _c("div", [
              _c(
                "div",
                { staticClass: "font-weight-medium input-label--text" },
                [_vm._v("Total Value")]
              ),
              _c("h5", { staticClass: "font-weight-bold" }, [
                _vm._v("$2,231.31"),
              ]),
              _c("div", { staticClass: "textLight--text" }, [
                _vm._v("12.00431 ETH"),
              ]),
            ]),
          ]),
          _c("div", [
            _c("div", { staticClass: "font-weight-medium input-label--text" }, [
              _vm._v("ETH Balance"),
            ]),
            _c("h5", { staticClass: "font-weight-bold" }, [
              _vm._v("$2,000.00"),
            ]),
            _c("div", { staticClass: "textLight--text" }, [
              _vm._v("8.00431 ETH"),
            ]),
          ]),
          _c("div", [
            _c("div", { staticClass: "font-weight-medium input-label--text" }, [
              _vm._v("Value of Tokens"),
            ]),
            _c("h5", { staticClass: "font-weight-bold" }, [_vm._v("$231.31")]),
            _c("div", { staticClass: "textLight--text" }, [_vm._v("4 tokens")]),
          ]),
          _c(
            "div",
            [
              _c("mew-button", {
                attrs: {
                  title: _vm.panel.length == 0 ? "Show tokens" : "Hide tokens",
                  "btn-style": "transparent",
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.openPanel.apply(null, arguments)
                  },
                },
              }),
            ],
            1
          ),
        ]
      ),
      _c(
        "v-expansion-panels",
        {
          attrs: { multiple: "", flat: "" },
          model: {
            value: _vm.panel,
            callback: function ($$v) {
              _vm.panel = $$v
            },
            expression: "panel",
          },
        },
        [
          _c(
            "v-expansion-panel",
            [_c("v-expansion-panel-content", [_c("tools-table")], 1)],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass:
            "d-lg-none text-center py-4 greenPrimary--text font-weight-medium",
          on: { click: _vm.openPanel },
        },
        [
          _vm._v(
            " " +
              _vm._s(_vm.panel.length == 0 ? "Show tokens" : "Hide tokens") +
              " "
          ),
          _c(
            "v-icon",
            {
              staticClass: "rotate",
              class: _vm.panel.length == 0 ? "" : "r180",
              attrs: { color: "greenPrimary" },
            },
            [_vm._v("mdi-chevron-down")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }